import React from "react"
import styled from "styled-components"
import { useGalleryData } from "../hooks/useGalleryData"
import Img from "gatsby-image"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import PageGalley from "../components/gallery/pageGalley"
import LetsBeSocial from "../components/gallery/letsBeSocial"
import PageHeader from "../components/pageHeader"
import { withPreview } from "gatsby-source-prismic"

const Gallery = ({ data: previewData }) => {
  const { uid, title, tagline, image, sections, bookData } = useGalleryData(
    previewData
  )
  return (
    <Layout bookBannerData={bookData}>
      <SEO title={uid} />
      <Container>
        <ImageContainer>
          <Img fluid={image.fluid} alt={image.alt || "hero"} />
        </ImageContainer>
        <PageHeader title={title} tagline={tagline} />
        <PageGalley images={sections[0].items} />
        <LetsBeSocial {...sections[1].primary} items={sections[1].items} />
      </Container>
    </Layout>
  )
}

export default withPreview(Gallery)

const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 1024px) {
    padding: 40px;
  }
  @media (max-width: 720px) {
    padding: 16px;
  }
`
const ImageContainer = styled.div`
  .gatsby-image-wrapper {
    min-height: 300px;
  }
`
